<template>
  <Transition>
    <UiModal
      v-if="displayModal"
      class="welcome-mat-modal"
      @close="closeWelcomeMat"
    >
      <template #body>
        <div class="welcome-mat-container">
          <h1 class="welcome-mat__title">
            <span class="welcome-mat__title--bold"
              >{{ $t('welcomeMat.title') }}
            </span>
            <span>
              {{
                $t('welcomeMat.countryTitle', {
                  country: getCountryShortName(siteCountryCode),
                })
              }}
            </span>
          </h1>

          <h2 class="welcome-mat__subtitle body-m">
            {{ $t('welcomeMat.subtitle') }}
          </h2>

          <div class="welcome-mat__promotion body-m">
            <div
              v-for="promotion in $tm('welcomeMat.promotionData')"
              :key="promotion"
              class="welcome-mat__promotion--item"
            >
              <UiIcon
                size="var(--size-typography-size-16)"
                name="check"
                :is-clickable="true"
              />
              <span> {{ $rt(promotion) }} </span>
            </div>
          </div>

          <div
            v-if="!isLocalShippingAvailableForUser"
            class="welcome-mat__non-shipping-country body-s"
          >
            <span> {{ $t('welcomeMat.noShippingMessage') }} <br /> </span>
            <UiSmartLink class="welcome-mat__link" to="/international">
              {{ $t('welcomeMat.clickHereText') }}
            </UiSmartLink>
            <span>{{ $t('welcomeMat.findStoreText') }} </span>
          </div>

          <UiButton
            class="welcome-mat__action"
            :type="
              isLocalShippingAvailableForUser
                ? BtnType.PRIMARY
                : BtnType.SECONDARY
            "
            :text="
              $t(
                `welcomeMat.${isLocalShippingAvailableForUser ? 'shippingCta' : 'nonShippingCta'}`
              ).toUpperCase()
            "
            @click="closeWelcomeMat"
          />
          <div
            v-if="isLocalShippingAvailableForUser"
            class="welcome-mat__shipping-country body-s"
          >
            {{
              $t('welcomeMat.shippingCountryMessage', {
                country: getCountryFullName(siteCountryCode),
              })
            }}
            <UiSmartLink
              class="welcome-mat__link"
              :to="`https://${userShippingCountryDomain}`"
            >
              {{
                $t('welcomeMat.shippingCountryName', {
                  country: getCountryFullName(userOriginCountryCode),
                })
              }}
            </UiSmartLink>
          </div>
        </div>
      </template>
    </UiModal>
  </Transition>
</template>

<script lang="ts" setup>
import UiIcon from '@sephora-asia/ui-icon'
import UiModal from '@sephora-asia/ui-modal'
import UiButton, { BtnType } from '@sephora-asia/ui-button'
import UiSmartLink from '@sephora-asia/ui-smart-link'

const { countryCode: siteCountryCode } = useI18nLocale()

const { getCountryFullName, getCountryShortName } = useCountryHelper()
const {
  isLocalShippingAvailableForUser,
  userOriginCountryCode,
  userShippingCountryDomain,
} = useUserCountryHelper()

const displayModal = ref(false)

const showMat = useSessionStorage(
  `welcome-mat-shown-${siteCountryCode.toUpperCase()}`,
  false
)

function closeWelcomeMat() {
  displayModal.value = false
  showMat.value = true
}

onMounted(() => {
  if (!showMat.value) {
    const isSameCountryCode =
      userOriginCountryCode.value === siteCountryCode.toUpperCase()

    displayModal.value = !!userOriginCountryCode.value && !isSameCountryCode
  }
})
</script>

<style lang="scss" scoped>
@mixin bgStripes {
  background: repeating-linear-gradient(
    black,
    black 20%,
    transparent 5%,
    transparent 40%
  );
  content: '';
  position: absolute;
  height: 100px;
  width: 100px;
  z-index: -1;

  @include mobile {
    display: none;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.welcome-mat-modal {
  :deep(.content) {
    border-radius: 0px !important;
  }
}

.welcome-mat-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px auto 20px;
  min-height: 300px;
  text-align: center;

  &:before {
    top: -74px;
    left: -25px;
    clip-path: polygon(0 0, 100% 0, 10% 100%, 0% 100%);
    @include bgStripes();
  }

  &:after {
    bottom: -44px;
    right: -25px;
    clip-path: polygon(90% 0, 100% 0, 100% 100%, 0% 100%);
    @include bgStripes();
  }
}

.welcome-mat {
  &__title {
    font-size: 34px;
    line-height: 36px;
    // TODO: Replace this with typography defined in the design system
    font-family: var(--asset-font-sephoraserif-name);

    &--bold {
      font-weight: 700;
    }
  }

  &__subtitle {
    font-size: 20px;
    width: calc(max(70%, 250px));
    margin: 15px auto;
  }

  &__promotion {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--item {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }

  &__link {
    color: var(--color-base-black);

    &:hover {
      color: var(--color-base-red-100);
    }
  }

  &__action {
    border-radius: 0;
  }

  &__non-shipping-country {
    margin-bottom: 20px;
  }

  &__shipping-country {
    margin-top: 20px;
  }
}
</style>
